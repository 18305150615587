// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

//import "prismjs/themes/prism.css"
import "./src/styles/global.scss"
import "./src/styles/pagenation.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import "prismjs/themes/prism-okaidia.css"
